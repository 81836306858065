import {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {MENU_ITEMS as MenuItems} from './shared/navbar/menu-items';
import Navbar from './shared/navbar/navbar';
import NotFound from './components/not-found/not-found';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import Login from './components/login/login';
import {config} from './common/config';

const client = new ApolloClient({
  uri: `${config.apiUrl}/graphql`,
  cache: new InMemoryCache()
});

const Routes = () => {
  return (
    <Switch>
      {MenuItems.map(item => {
        return <Route key={item.title} path={item.url} component={item.component} />;
      })}
      <Route component={Login} path="/login" />
      <Route component={NotFound} />
    </Switch>
  );
};

export default class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Navbar />
          <Routes />
        </Router>
      </ApolloProvider>
    );
  }
}
