import React, {Component, ReactNode} from 'react';
import backgroundVideo from '../../assets/video/background.mp4';
import './not-found.css';

const Background = () => (
  <video autoPlay loop muted id="video" className="video" data-testid="background">
    <source src={backgroundVideo} type="video/mp4" />
  </video>
);

const Error = () => {
  const errorCode = '404';
  const errorMessage = 'Not Found';
  return (
    <div className="error-wrapper" data-testid="error-wrapper">
      <p className="error-code" title={errorCode} data-testid="error-code">
        {errorCode}
      </p>
      <p className="error-message" data-testid="error-message">
        {errorMessage}
      </p>
    </div>
  );
};

const Title = () => {
  const title = 'The Green World is coming soon...';
  return (
    <div className="title-wrapper" data-testid="title-wrapper">
      <p className="title" data-testid="title">
        {title}
      </p>
    </div>
  );
};

export default class NotFound extends Component {
  public render(): ReactNode {
    return (
      <section className="notfound-section" data-testid="section">
        <Background />
        <Error />
        <Title />
      </section>
    );
  }
}
