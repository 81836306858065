import React, {Component, ReactNode} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
import {gql, useMutation} from '@apollo/client';
import 'react-toastify/dist/ReactToastify.css';
import {config} from '../../common/config';
import './login.css';

const SOCIAL_LOGIN_MUTATION = gql`
  mutation SocialLogin($input: SocialLoginInput!) {
    socialLogin(input: $input) {
      token
    }
  }
`;

const GoogleButton = () => {
  const [socialLogin] = useMutation(SOCIAL_LOGIN_MUTATION);

  const onGoogleSSOResponse = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    socialLogin({
      variables: {input: {authToken: response.code, provider: 'google'}}
    })
      .then(res => {
        const {
          data: {
            socialLogin: {token}
          }
        } = res;
        sessionStorage.setItem('token', token);
        window.location.href = '/home';
      })
      .catch(() => {
        toast('Login unsuccessful.', {type: 'error'});
      });
  };

  const onGoogleSSOFailure = (response: {error: string}): void => {
    if (response.error === 'popup_closed_by_user') return;
    toast('Login unsuccessful.', {type: 'error'});
  };

  return (
    <GoogleLogin
      clientId={config.googleClientId}
      onSuccess={onGoogleSSOResponse}
      onFailure={onGoogleSSOFailure}
      cookiePolicy={'single_host_origin'}
      buttonText={'Log in with Google'}
      responseType="code"
      prompt="consent"
      accessType="offline"
      // Provide 'postmessage' to redirectUri prop instead of the actual URI to prevent
      // redirect_uri_mismatch error when sending the authToken to the server:
      // https://github.com/anthonyjgrove/react-google-login/issues/66
      redirectUri="postmessage"
      className="google-button"
    />
  );
};

export default class Login extends Component {
  public render(): ReactNode {
    return (
      <section className="login-section">
        <div className="login-container">
          <div className="social-buttons">
            <GoogleButton />
          </div>
        </div>
        <ToastContainer />
      </section>
    );
  }
}
