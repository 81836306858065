import Home from '../../components/home/home';

export const MENU_ITEMS = [
  {
    title: 'Home',
    url: '/home',
    cName: 'nav-links',
    component: Home
  }
];

export const NAVBAR_PATHS = MENU_ITEMS.map(item => item.url);
