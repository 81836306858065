import {Component} from 'react';
import {Button} from './button';
import {MENU_ITEMS as MenuItems, NAVBAR_PATHS as NavbarPaths} from './menu-items';
import './navbar.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlobeAmericas, faTimes, faBars} from '@fortawesome/free-solid-svg-icons';

export default class Navbar extends Component {
  state = {clicked: false};

  handleClick = () => {
    this.setState({clicked: !this.state.clicked});
  };

  routeChange = (path: string) => {
    window.location.href = path;
  };

  render() {
    return !NavbarPaths.includes(window.location.pathname) ? null : (
      <nav className="navbar-items">
        <h1 className="navbar-logo">
          The Green World
          <FontAwesomeIcon icon={faGlobeAmericas} className="fa-react" />
        </h1>
        <div className="menu-icon" onClick={this.handleClick}>
          <FontAwesomeIcon icon={this.state.clicked ? faTimes : faBars} />
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <a className={item.cName} href={item.url}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
        <Button onClick={() => this.routeChange('/login')}>Login</Button>
        <Button>Sign up</Button>
      </nav>
    );
  }
}
