import './button.css';

interface ButtonInterface {
  children?: string;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
  buttonStyle?: string;
  buttonSize?: string;
}

const STYLES = ['btn-primary', 'btn-outline'];

const SIZES = ['btn-medium', 'btn-large'];

export const Button = ({children, type, onClick, buttonStyle, buttonSize}: ButtonInterface) => {
  const checkButtonStyle = buttonStyle && STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
  const checkButtonSize = buttonSize && SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>
      {children}
    </button>
  );
};
