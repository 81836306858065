import * as env from 'env-var';

interface Config {
  apiUrl: string;
  googleClientId: string;
}

export const config: Config = {
  apiUrl: env.get('REACT_APP_API_URL').required().asString(),
  googleClientId: env.get('REACT_APP_GOOGLE_CLIENT_ID').default('').asString()
};
